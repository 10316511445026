.slider {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    gap: 10px;
}

.slide {
    width: 30%;
    border-radius: 15px;
    box-shadow: 0 0 10px #cccccc;
}

@media all and (min-width: 320px) and (max-width: 768px) {
    .slider {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        gap: 10px;
    }

    .slide {
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 0 10px #cccccc;
    }
}
