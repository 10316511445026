.main-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

.content-layout {
    display: flex;
    width: 100%;
    min-height: 79vh;
    border-radius: 15px;
    background: linear-gradient(-90deg, #2D3C82, #01939A);
    padding: 25px 30px 25px 50px;
}

@media all and (min-width: 320px) and (max-width: 768px) {
    .main-container {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;
        align-items: center;
    }

    .content-layout {
        display: flex;
        width: 100%;
        min-height: 0;
        border-radius: 15px;
        background: linear-gradient(180deg, #2D3C82, #01939A);
        padding: 15px 0;
    }
}
