.header {
    display: grid;
    grid-template-columns: 2fr 4fr;
    align-items: center;
    padding: 10px 25px;
}

.header-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.header-title__name {
    font-size: 36px;
    font-weight: 600;
    color: #FFFFFF;
}

@media all and (min-width: 320px) and (max-width: 768px) {
    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px 15px;
        gap: 5px;
    }

    .header-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .header-title__name {
        font-size: 36px;
        font-weight: 600;
        color: #FFFFFF;
    }
}
