.navigation {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    align-items: center;
    gap: 50px;
    width: 70%;
    margin-left: auto;
}

.navigation-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
}

.navigation-item:hover {
    border-radius: 15px;
    box-shadow: 0 0 10px #FF9840;
}

.navigation-item:active {
    border-radius: 15px;
    background: linear-gradient(0deg, #ffb473, #ffd673);
    box-shadow: none;
}

.navigation-item__active {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 15px;
    box-shadow: 0 0 10px #FF9840;
}

.navigation-item__text {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFFFFF;
}

.navigation-item:active .navigation-item__text {
    color: #FFFFFF;
}

@media all and (min-width: 320px) and (max-width: 768px) {
    .navigation {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding-top: 5px;
        border-top: 1px solid #FFFFFF;
    }

    .navigation-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        height: 40px;
        text-decoration: none;
        cursor: pointer;
    }

    .navigation-item:hover {
        border-radius: 15px;
        box-shadow: 0 0 10px #FF9840;
    }

    .navigation-item:active {
        border-radius: 15px;
        background: linear-gradient(0deg, #ffb473, #ffd673);
        box-shadow: none;
    }

    .navigation-item__active {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        height: 40px;
        text-decoration: none;
        cursor: pointer;
        border-radius: 15px;
        box-shadow: 0 0 10px #FF9840;
    }

    .navigation-item__text {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
    }

    .navigation-item:active .navigation-item__text {
        color: #FFFFFF;
    }
}
