.technologies {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
}

.technologies-title {
    width: 100%;
    padding: 10px 0;
    color: #071A71;
    font-size: 25px;
    font-weight: 600;
}

.technologies-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.technology-item {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 10px;
    border-radius: 15px;
    padding: 10px 20px;
}

.technology-item__name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    color: #071A71;
    padding: 0 15px 0 10px;
}

.technology-item__rate {
    width: 100%;
    height: 15px;
    background: linear-gradient(0deg, #2D3C82, #01939A);
    border-radius: 10px;
}

.technology-item__rate__Junior {
    width: 25%;
    height: 15px;
    background: linear-gradient(0deg, #ffb473, #ffd673);
    border-radius: 10px;
    opacity: inherit;
}

.technology-item__rate__Middle {
    width: 50%;
    height: 15px;
    background: linear-gradient(0deg, #ffb473, #ffd673);
    border-radius: 10px;
}

.technology-item__rate__Advanced {
    width: 75%;
    height: 15px;
    background: linear-gradient(0deg, #ffb473, #ffd673);
    border-radius: 10px;
}

@media all and (min-width: 320px) and (max-width: 768px) {
    .technologies {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 25px;
    }

    .technologies-title {
        width: 100%;
        padding: 10px 0;
        color: #071A71;
        font-size: 16px;
        font-weight: 600;
    }

    .technologies-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
    }

    .technology-item {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 5px;
        border-radius: 15px;
        padding: 5px 10px;
    }

    .technology-item__name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        color: #071A71;
        padding: 0 15px 0 10px;
    }

    .technology-item__rate {
        width: 100%;
        height: 15px;
        background: linear-gradient(0deg, #2D3C82, #01939A);
        border-radius: 10px;
    }

    .technology-item__rate__Junior {
        width: 25%;
        height: 15px;
        background: linear-gradient(0deg, #ffb473, #ffd673);
        border-radius: 10px;
        opacity: inherit;
    }

    .technology-item__rate__Middle {
        width: 50%;
        height: 15px;
        background: linear-gradient(0deg, #ffb473, #ffd673);
        border-radius: 10px;
    }

    .technology-item__rate__Advanced {
        width: 75%;
        height: 15px;
        background: linear-gradient(0deg, #ffb473, #ffd673);
        border-radius: 10px;
    }
}
