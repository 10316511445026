.project-list-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.project-item {
     display: flex;
     flex-direction: column;
     gap: 10px;
     border-radius: 15px;
     padding: 10px 20px;
     color: #071A71;
     font-size: 16px;
     text-align: justify;
    box-shadow: 0 0 10px #cccccc;
}

.project-item-name {
    font-size: 24px;
    font-weight: 600;
 }

.project-item-sub-title {
    font-weight: 600;
}

.project-item-photo {
    width: 50%;
    float: right;
    margin-left: 10px;
}

.project-item-photo__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-shadow: 0 0 10px #cccccc;
}

.project-item-stack-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
}

.project-item-stack-list__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    background: linear-gradient(0deg, #ffb473, #ffd673);
    height: 30px;
    border-radius: 15px;
}

@media all and (min-width: 320px) and (max-width: 768px) {
    .project-list-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }

    .project-item {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 15px;
        padding: 5px 10px;
        color: #071A71;
        font-size: 14px;
        text-align: justify;
        box-shadow: 0 0 10px #cccccc;
    }

    .project-item-name {
        font-size: 24px;
        font-weight: 600;
    }

    .project-item-sub-title {
        font-weight: 600;
    }

    .project-item-photo {
        width: 50%;
        float: right;
        margin-left: 10px;
    }

    .project-item-photo__img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }

    .project-item-stack-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        width: 100%;
    }

    .project-item-stack-list__item {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(0deg, #ffb473, #ffd673);
        padding: 0 10px;
        height: 30px;
        border-radius: 15px;
    }
}
