.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    min-height: 79.5vh;
    padding: 40px 50px;
    background: linear-gradient(90deg, #2D3C82, #01939A);
    border-radius: 15px;
    gap: 46px;
}

.sidebar-photo {
    width: 250px;
    height: 250px;
    box-shadow: 0 0 10px #cccccc;
    border-radius: 130px;
}

.sidebar-photo__img {
    height: 100%;
    width: 100%;
    border-radius: 130px;
}

.sidebar-title {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.sidebar-title__name {
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
}

.sidebar-title__profession {
    font-size: 16px;
    font-weight: 600;
    color: #ffd673;
    text-align: center;
}

.sidebar-description {
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    text-align: justify;
    background: #FFFFFF;
    border-radius: 15px;
    color: #071A71;
}

.sidebar-links {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    width: 70%;
    border-radius: 15px;
    height: 50px;
}

.sidebar-links__icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border: none;
    border-radius: 30px;
    background: transparent;
    cursor: pointer;
}

.sidebar-links__icon:hover {
    border: 2px solid #FF9840;
}

.sidebar-links__icon:active {
    background: #FF9840;
}

.sidebar-send-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    padding: 15px 15px;
    border-radius: 15px;
    border: none;
    background: linear-gradient(0deg, #ffb473, #ffd673);
    margin-top: auto;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.sidebar-send-btn:hover {
    box-shadow: 0 0 10px #ffb473;
}

.sidebar-send-btn:active {
    background: #ffb473;
}

@media all and (min-width: 320px) and (max-width: 768px) {
    .sidebar {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 0;
        background: linear-gradient(0deg, #2D3C82, #01939A);
        border-radius: 15px;
        padding: 20px 0;
        gap: 20px;
    }

    .sidebar-photo {
        width: 100px;
        height: 100px;
        box-shadow: 0 0 10px #cccccc;
        border-radius: 100px;
    }

    .sidebar-photo__img {
        height: 100%;
        width: 100%;
        border-radius: 100px;
    }

    .sidebar-title {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }

    .sidebar-title__name {
        font-size: 24px;
        font-weight: 600;
        color: #FFFFFF;
    }

    .sidebar-title__profession {
        font-size: 16px;
        font-weight: 600;
        color: #ffd673;
        text-align: center;
    }

    .sidebar-description {
        display: flex;
        width: 90%;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
        text-align: justify;
        background: #FFFFFF;
        border-radius: 15px;
        color: #071A71;
    }

    .sidebar-links {
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        width: 50%;
        border-radius: 15px;
        height: 50px;
    }

    .sidebar-links__icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        border: none;
        border-radius: 30px;
        background: transparent;
        cursor: pointer;
    }

    .sidebar-links__icon:hover {
        border: 2px solid #FF9840;
    }

    .sidebar-links__icon:active {
        background: #FF9840;
    }

    .sidebar-send-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        padding: 15px 15px;
        border-radius: 15px;
        border: none;
        background: linear-gradient(0deg, #ffb473, #ffd673);
        margin-top: 0;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }

    .sidebar-send-btn:hover {
        box-shadow: 0 0 10px #ffb473;
    }

    .sidebar-send-btn:active {
        background: #ffb473;
    }
}
