.change-language {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
}

.change-language-single {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    background: transparent;
}

.change-language-single:hover {
    box-shadow: 0 0 10px #FF9840;
}

.change-language-single:active {
    box-shadow: 0 0 10px #FF9840;
}

.change-language-single__icon {
    width: 40px;
}
